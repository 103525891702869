import './App.css';
import {useState, useContext, useEffect} from "react";
import * as DateFns from 'date-fns';
import { uk } from 'date-fns/locale';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { capitalizeString, calculateOrthodoxEaster, DateFormat } from './utils/date.js'
import {YearContext, YearProvider} from './YearContext.js'
import Header from './Header'

function CalendarYear() {
  const { year } = useContext(YearContext);
  const data = [];

  let daysInMonth = 0;
  let monthName = "";
  let dayName = "";

  for (let month = 0; month <= 11; month++) {
    daysInMonth = DateFns.getDaysInMonth(new Date(year, month))
    monthName = DateFormat(new Date(year, month), 'LLLL')

    data.push(<div key={`${year}-${month}`} className="font-bold my-4">{capitalizeString(monthName)}</div>);

    for (let day = 1; day <= daysInMonth; day++) {
      dayName = DateFormat(new Date(year, month, day), 'EEEE')
      data.push(<p key={`${month}-${day}`}>{capitalizeString(monthName)} {day} {capitalizeString(dayName)}</p>);
    }
  }

  return (
    <>
      {data}
    </>
  )
}

function About() {
  function getCurrentWeekDates(date) {
    // Find the start and end of the current week based on the input date
    const startDate = DateFns.startOfWeek(date, { weekStartsOn: 1 }); // Assuming Monday is the first day of the week (adjust as needed)
    const endDate = DateFns.endOfWeek(date, { weekStartsOn: 1 });

    // Generate an array of dates for the current week
    const weekDates = DateFns.eachDayOfInterval({ start: startDate, end: endDate });

    return weekDates;
  }

// Example usage:
  const currentDate = new Date()
  const currentWeekDates = getCurrentWeekDates(currentDate)
  const [dynamicDate, setDynamicDate] = useState(currentDate)
  const [dynamicDay, setDynamicDay] = useState(DateFns.format(currentDate, 'd'))
  const [dynamicMonth, setDynamicMonth] = useState(capitalizeString(DateFns.format(currentDate, 'MMMM', { locale: uk })))
  const handleClick = (event) => {
    const year = event.target.getAttribute("data-year");
    const month = event.target.getAttribute("data-month");
    const day = event.target.getAttribute("data-day");
    setDynamicDate(new Date(year, month, day))
  }

  const renderedWeekDays = currentWeekDates.map((date, index) => {
    let currentDay = DateFns.isToday(date) ? "pb-4 border-b-emerald-200 border-b-4" : ""
    let sundayStyle = DateFns.isSunday(date) ? "bg-orange-600 bg-opacity-50 " : ""

    return (
      <div
        className={sundayStyle + "cursor-pointer flex flex-col items-center w-20 mx-1 md:mx-2 py-4 md:py-8 rounded-3xl border border-gray-300"}
        data-year={date.getFullYear()}
        data-month={date.getMonth()}
        data-day={DateFns.format(date, 'd')}
        key={index}
        onClick={handleClick}
      >
        <div className="font-medium" data-year={date.getFullYear()} data-month={date.getMonth()} data-day={DateFns.format(date, 'd')}>{capitalizeString(DateFns.format(date, 'ccc', { locale: uk }))}</div>
        <div className={currentDay} data-year={date.getFullYear()} data-month={date.getMonth()} data-day={DateFns.format(date, 'd')}>{capitalizeString(DateFns.format(date, 'd', { locale: uk }))}</div>
      </div>
    )
  });

  useEffect(() => {
    setDynamicDay(DateFns.format(dynamicDate, 'd'))
    setDynamicMonth(capitalizeString(DateFns.format(dynamicDate, 'MMMM', { locale: uk })))
  }, [dynamicDate])


  return (
    <>
      <div className="flex justify-center text-sm md:text-xl mt-6 mb-10">
        {renderedWeekDays}
      </div>
      <div className="font-medium text-6xl">
        {dynamicDay}
      </div>
      <div className="text-gray-500 text-3xl">
        {dynamicMonth}
      </div>
      <div className="mt-10 text-gray-700">
        Dolorem ipsum dolor sit amet, consectetur adipiscing elit.
        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </div>
    </>
  )
}

function MainCalendar() {
  const { year, setYear } = useContext(YearContext);

  const easter = calculateOrthodoxEaster(year)
  const startLent = DateFns.subDays(easter, 48)
  const fatherOfSixCouncil = DateFns.nextSunday(new Date(year, 9, 11))
  const handleChange = (event) => {
    if(event.target.value.length === 4) {
      setYear(event.target.value)
    }
  }

  return (
    <>
        <div className="mb-6">

            <input
              type="number"
              placeholder="Year"
              value={year}
              onChange={handleChange}
              min="1950"
              max="2050"
              className="block text-2xl p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            />

        </div>
        <p>Easter {easter.toDateString()}</p>
        <p>Lent starts {startLent.toDateString()}</p>
        <p>Fathers of Six Council – {DateFns.format(fatherOfSixCouncil, 'do', { locale: uk })} {DateFns.format(fatherOfSixCouncil, 'MMMM', { locale: uk })}</p>
        <CalendarYear />
    </>
  );
}

function App() {
  return (
    <div className="lg:bg-gray-300 min-h-screen lg:pt-16 ">
      <div className="text-md md:text-2xl max-w-screen-lg bg-white px-8 md:px-16 pt-6 pb-16 mx-auto lg:border border-opacity-30 border-l-0 border-t-0 border-gray-600 lg:border-r-8 lg:border-b-8 lg:rounded-3xl">
        <YearProvider>
            <BrowserRouter>
              <Header />
                  <Routes>
                    <Route path="/" element={<MainCalendar />} />
                    <Route path="/about" element={<About />} />
                  </Routes>
            </BrowserRouter>
        </YearProvider>
      </div>
    </div>
  );
}

export default App;
