import { YearContext } from "./YearContext"
import { useContext } from "react"
import { Link } from "react-router-dom"

export default function Header() {
  const { year } = useContext(YearContext);

  return (
    <>
      <div className="text-center">
        <Link to="/" className="inline-block bg-green-400 bg-opacity-50 px-5 py-2 rounded-3xl">Календар на {year} рік </Link>
        <Link to="/about" className="inline-block bg-green-400 bg-opacity-50 px-5 py-2 ml-4 rounded-3xl">Сьогодні</Link>
      </div>
    </>
  )
}
