import { createContext, useState } from "react";

export const YearContext = createContext()

export const YearProvider = ({ children }) => {
  // Define the state or data you want to provide via context
  const [year, setYear] = useState(new Date().getFullYear());

  // Create a value object with the data and any functions to update it
  const YearValue = {
    year,
    setYear,
  };

  // Provide the context value to its children
  return <YearContext.Provider value={YearValue}>{children}</YearContext.Provider>;
};
