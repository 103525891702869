import * as DateFns from "date-fns";
import { uk } from "date-fns/locale";

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const DateFormat = (date, format) => {
  return DateFns.format(date, format, { locale: uk })
}

export const calculateOrthodoxEaster = (year) => {
  function easter_a(year) {
    return year % 4;
  }

  function easter_b(year) {
    return year % 7;
  }

  function easter_c(year) {
    return year % 19;
  }

  function easter_d(year) {
    return (19 * easter_c(year) + 15) % 30;
  }

  function easter_e(year) {
    return (2 * easter_a(year) + 4 * easter_b(year) - easter_d(year) + 34) % 7;
  }

  const month = Math.floor((easter_d(year) + easter_e(year) + 114) / 31);
  const day = ((easter_d(year) + easter_e(year) + 114) % 31) + 1;

  return new Date(year, month - 1, day + 13);
}
